<template>
  <div class="toast-test">
    <h1>Toast 测试页面</h1>
    <div class="button-group">
      <van-button type="primary" @click="showDefaultToast">默认 Toast</van-button>
      <van-button type="success" @click="showSuccessToast">成功 Toast</van-button>
      <van-button type="warning" @click="showWarningToast">警告 Toast</van-button>
      <van-button type="danger" @click="showErrorToast">错误 Toast</van-button>
      <van-button @click="showLoadingToast">加载中 Toast</van-button>
      <van-button @click="showCustomToast">自定义 Toast</van-button>
    </div>
  </div>
</template>

<script>
import { showToast, showLoadingToast } from 'vant';


export default {
  name: 'ToastTest',
  methods: {
    showDefaultToast() {
      showToast('这是一个默认的 Toast');
    },
    showSuccessToast() {
      showToast({ type: 'success', message: '操作成功！' });
    },
    showWarningToast() {
      showToast({ type: 'warning', message: '警告：请注意！' });
    },
    showErrorToast() {
      showToast({ type: 'fail', message: '操作失败，请重试' });
    },
    showLoadingToast() {
      showLoadingToast({ message: '加载中...', forbidClick: true, duration: 0 });
      setTimeout(() => {
        showLoadingToast.clear();
      }, 3000);
    },
    showCustomToast() {
      showToast({ message: '自定义 Toast', icon: 'like-o', position: 'top' });
    },
  },
};
</script>

<style scoped>
.toast-test {
  padding: 20px;
  text-align: center;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 300px;
  margin: 0 auto;
}
</style>